.overlay {
  background: #f7f7f7;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 300ms ease-out;
  width: 100%;
  z-index: 2;
}
.overlay.animateIn {
  opacity: 0.75;
}

.Spinner {
  z-index: 100;
}
.Spinner.primary {
  animation: loadPrimary 1.7s infinite ease, round 1.7s infinite ease;
  border-radius: 50%;
  color: #ffffff;
  font-size: 40px;
  height: 1em;
  left: 50%;
  overflow: hidden;
  position: absolute;
  text-indent: -9999em;
  top: 50%;
  transform: translateZ(0);
  width: 1em;
}
.Spinner.primary.blue {
  color: #007cff;
}
.Spinner.secondary, .Spinner.secondary:after {
  border-radius: 50%;
  height: 1.25rem;
  width: 1.25rem;
}
.Spinner.secondary {
  animation: loadAnimation 1.1s infinite linear;
  border-bottom: 0.09375rem solid #d9d9d9;
  border-left: 0.09375rem solid #007cff;
  border-right: 0.09375rem solid #d9d9d9;
  border-top: 0.09375rem solid #d9d9d9;
  font-size: 0.625rem;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
}

@keyframes loadPrimary {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%, 95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%, 59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loadAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}