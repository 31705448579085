/// Spinner component styles
@import '../../../scss/core/index';

.overlay {
  background: color(mono, pale, lightest);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 300ms ease-out;
  width: 100%;
  z-index: z-index(pull);

  &.animateIn {
    opacity: .75;
  }
}

.Spinner {
  z-index: z-index(modal);

  &.primary {
    animation: loadPrimary 1.7s infinite ease, round 1.7s infinite ease;
    border-radius: 50%;
    color: #ffffff;
    font-size: 40px;
    height: 1em;
    left: 50%;
    overflow: hidden;
    position: absolute;
    text-indent: -9999em;
    top: 50%;
    transform: translateZ(0);
    width: 1em;

    &.blue {
      color: get($colors, primary, default);
    }
  }

  &.secondary,
  &.secondary:after {
    border-radius: 50%;
    height: rem(20);
    width: rem(20);
  }

  &.secondary {
    animation: loadAnimation 1.1s infinite linear;
    border-bottom: rem(1.5) solid get($colors, mono, pale, default);
    border-left: rem(1.5) solid get($colors, primary, default);
    border-right: rem(1.5) solid get($colors, mono, pale, default);
    border-top: rem(1.5) solid get($colors, mono, pale, default);

    font-size: rem(10);
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
  }
}

// Animations - primary
@keyframes loadPrimary {

  0% {
    box-shadow: 0 -.83em 0 -.4em, 0 -.83em 0 -.42em, 0 -.83em 0 -.44em, 0 -.83em 0 -.46em, 0 -.83em 0 -.477em;
  }

  5%,
  95% {
    box-shadow: 0 -.83em 0 -.4em, 0 -.83em 0 -.42em, 0 -.83em 0 -.44em, 0 -.83em 0 -.46em, 0 -.83em 0 -.477em;
  }

  10%,
  59% {
    box-shadow: 0 -.83em 0 -.4em, -.087em -.825em 0 -.42em, -.173em -.812em 0 -.44em, -.256em -.789em 0 -.46em, -.297em -.775em 0 -.477em;
  }

  20% {
    box-shadow: 0 -.83em 0 -.4em, -.338em -.758em 0 -.42em, -.555em -.617em 0 -.44em, -.671em -.488em 0 -.46em, -.749em -.34em 0 -.477em;
  }

  38% {
    box-shadow: 0 -.83em 0 -.4em, -.377em -.74em 0 -.42em, -.645em -.522em 0 -.44em, -.775em -.297em 0 -.46em, -.82em -.09em 0 -.477em;
  }

  100% {
    box-shadow: 0 -.83em 0 -.4em, 0 -.83em 0 -.42em, 0 -.83em 0 -.44em, 0 -.83em 0 -.46em, 0 -.83em 0 -.477em;
  }
}

@keyframes round {

  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


// Animations - secondary
@keyframes loadAnimation {

  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
