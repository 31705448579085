body {
  height: 100vh;
  margin: 0;
}
body > div {
  height: 100%;
}

.wrap {
  height: 100%;
}
.wrap:before {
  background: white;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 300ms ease-out;
  z-index: 40;
}
.wrap > div:first-child {
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms ease-out;
  z-index: 40;
}
.wrap.loading {
  height: 100vh;
  position: relative;
  width: 100vw;
}
.wrap.loading:before {
  opacity: 1;
  pointer-events: all;
}
.wrap.loading > div:first-child {
  opacity: 1;
  pointer-events: all;
}